import React, { Component } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Layout from "../layout";
import FaqAll from "../components/FAQ/FaqAll"
import config from "../../data/SiteConfig";
import DownloadAPP from "../components/DownloadAPP/DownloadAPP";


class FaqPage extends Component {
  render() {
    return (
      <Layout>
        <div className="faq-container">
          <HelmetProvider>
            <Helmet title={`پرسش‌های متداول | ${config.siteTitle}`} />
          </HelmetProvider>
          <FaqAll />
        </div>
        <DownloadAPP />
      </Layout>
    );
  }
}

export default FaqPage;
