import React, { Component } from 'react';
import PropTypes from 'prop-types';

class AccordionSection extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  };
  onClick = () => {
    this.props.onClick(this.props.label);
  };
  render() {
    const {onClick, props: { isOpen, label }} = this;
    return (
      <div className="accordionItem">
        <div onClick={onClick} className="accordionHead">
          <div>
            {!isOpen && <span>+ </span>}
            {isOpen && <span>- </span>}
          </div>  
          <p className="q">{label}</p>
        </div>
          <div className="accordionBody" data-status={isOpen && 'open'}>
            {this.props.children}
          </div>
      </div>
    );
  }
}

export default AccordionSection;
